<template>
  <div>
    <div class="mb-1">
      <b-row
        class="text-center font-weight-bold"
      >
        <b-col
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-danger"
            block
            disabled
          >
            Talep Eşleşme Özeti
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-row>
        <!-- Eşleşme Süreç Sonuçları -->
        <b-col
          cols="12"
          md="6"
        >
          <!-- başlık -->
          <b-row class="border text-center font-weight-bold">
            <b-col>
              Eşleşme Süreç Sonuçları
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="8"
              md="8"
              class="text-danger border"
            >
              Eşlenen Aday
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="border"
            >
              {{ tblbaslik.eslesen }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="8"
              md="8"
              class="text-danger border"
            >
              Elenen Aday
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="border"
            >
              {{ tblbaslik.elenen }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="8"
              md="8"
              class="text-danger border"
            >
              Gerek Şart Sayısı
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="border"
            >
              {{ tblbaslik.gereksay }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="8"
              md="8"
              class="text-danger border"
            >
              Önemli Sayısı
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="border"
            >
              {{ tblbaslik.onemlisay }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="8"
              md="8"
              class="text-danger border"
            >
              Farketmez Sayısı
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="border"
            >
              {{ tblbaslik.farketmezsay }}
            </b-col>
          </b-row>
        </b-col>
        <!-- Eşleşme Talep Uyum Sonuçları -->
        <b-col
          cols="12"
          md="6"
        >
          <!-- başlık -->
          <b-row class="border text-center font-weight-bold">
            <b-col>
              Eşleşme Talep Uyum Sonuçları
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="8"
              md="8"
              class="text-danger border"
            >
              Kişisel Yeterlilik Uyum
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="border"
            >
              {{ tblbaslik.kyu.toFixed(2) }} / {{ tblbaslik.reqkyu.toFixed(2) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="8"
              md="8"
              class="text-danger border"
            >
              Mesleki Yeterlilik Uyum
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="border"
            >
              {{ tblbaslik.myu.toFixed(2) }} / {{ tblbaslik.reqmyu.toFixed(2) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="8"
              md="8"
              class="text-danger border"
            >
              Temel Yetenek Uyum
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="border"
            >
              {{ tblbaslik.temyu.toFixed(2) }} / {{ tblbaslik.reqtem.toFixed(2) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="8"
              md="8"
              class="text-danger border"
            >
              Teknik Yeterlilik Uyum
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="border"
            >
              {{ tblbaslik.tekyu.toFixed(2) }} / {{ tblbaslik.reqtek.toFixed(2) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="8"
              md="8"
              class="text-danger border"
            >
              Risk Alma Uyum
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="border"
            >
              {{ tblbaslik.rsku.toFixed(2) }} / {{ tblbaslik.reqrsk.toFixed(2) }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <br><br>
      <!-- 1.sıra
      <b-row class="border">
        <b-col
          cols="6"
          md="3"
          class="text-danger"
        >
          Eşlenen Aday
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          {{ tblbaslik.eslesen }}
        </b-col>
        <b-col
          cols="6"
          md="3"
          class="text-danger"
        >
          Kişisel Yeterlilik Uyum
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          SQ
        </b-col>
      </b-row> -->
      <!-- 2.sıra  -->
      <!-- <b-row class="border">
        <b-col
          cols="6"
          md="3"
          class="text-danger"
        >
          Elenen Aday
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          {{ tblbaslik.elenen }}
        </b-col>
        <b-col
          cols="6"
          md="3"
          class="text-danger"
        >
          Mesleki Yeterlilik Uyum
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          SQ
        </b-col>
      </b-row> -->
      <!-- 3.sıra  -->
      <!-- <b-row class="border">
        <b-col
          cols="6"
          md="3"
          class="text-danger"
        >
          Gerek Şart Sayısı
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          SQ
        </b-col>
        <b-col
          cols="6"
          md="3"
          class="text-danger"
        >
          Temel Yetenek Uyum
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          SQ
        </b-col>
      </b-row> -->
      <!-- 4.sıra  -->
      <!-- <b-row class="border">
        <b-col
          cols="6"
          md="3"
          class="text-danger"
        >
          Önemli Sayısı
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          SQ
        </b-col>
        <b-col
          cols="6"
          md="3"
          class="text-danger"
        >
          Teknik Yeterlilik Uyum
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          SQ
        </b-col>
      </b-row> -->
      <!-- 5.sıra  -->
      <!-- <b-row class="border">
        <b-col
          cols="6"
          md="3"
          class="text-danger"
        >
          Farketmez Sayısı
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          SQ
        </b-col>
        <b-col
          cols="6"
          md="3"
          class="text-danger"
        >
          Risk Alma Uyum
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          SQ
        </b-col>
      </b-row> -->
    </div>
    <br>
    <div>
      <b-row class="text-danger font-weight-bold">
        <b-col
          cols="6"
          md="6"
          class="border"
        >
          Elenme Faktörü
        </b-col>
        <b-col
          cols="2"
          md="2"
          class="border"
        >
          Sayı
        </b-col>
        <b-col
          cols="4"
          md="4"
          class="border"
        >
          Toplam Oranı
        </b-col>
      </b-row>
    </div>
    <div
      v-for="(s ,t) in elemeNeden.filter(z => z.sayac !==0)"
      :key="t"
    >
      <b-row>
        <b-col
          cols="6"
          md="6"
          class="border"
        >
          {{ s.tr }}
        </b-col>
        <b-col
          cols="2"
          md="2"
          class="border"
        >
          {{ s.sayac }}
        </b-col>
        <b-col
          cols="4"
          md="4"
          class="border"
        >
          %{{ ((s.sayac / tblbaslik.eslesen) * 100).toFixed(2) }}
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BButton, VBModal, BRow, BCol, // BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import * as apiTalep from '@/api/islemler/firmRequest'
import * as apiCert from '@/api/islemler/staffRequests/staffRequestCertifications'
import * as apiComp from '@/api/islemler/staffRequests/staffRequestComputers'
import * as apiLang from '@/api/islemler/staffRequests/staffRequestForeignLanguages'
import * as apiExp from '@/api/islemler/staffRequests/staffRequestPositionExperiences'
import * as apiQua from '@/api/islemler/staffRequests/staffRequestQualifications'
import * as apiSec from '@/api/islemler/staffRequests/staffRequestSectorExperiences'
// import * as apiFirm from '@/api/islemler/firminventory'
import * as apiEval from '@/api/islemler/Evaluation'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    // BBadge,

  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },

  data() {
    return {
      firmStaffRequestId: this.$route.params.firmStaffRequestId,
      firmRequest: {},
      tblbaslik: {
        eslesen: 0, elenen: 0, gereksay: 0, onemlisay: 0, farketmezsay: 0, kyu: 0, myu: 0, temyu: 0, tekyu: 0, rsku: 0, reqkyu: 0, reqmyu: 0, reqtem: 0, reqtek: 0, reqrsk: 0,
      },
      elemeNeden: [
        { en: 'GenderPoints', tr: 'Cinisyet', sayac: 0 },
        { en: 'MaritalStatusPoints', tr: 'Medeni hal', sayac: 0 },
        { en: 'BirthDatePoints', tr: 'Yaşı', sayac: 0 },
        { en: 'HeightCmPoints', tr: 'Boyu', sayac: 0 },
        { en: 'WeightKgPoints', tr: 'Kilosu', sayac: 0 },
        { en: 'TownPoints', tr: 'İlçe uyumsuzluğu', sayac: 0 },
        { en: 'MilitaryStatusPoints', tr: 'Askerlik durumu', sayac: 0 },
        { en: 'IsNotDisabledPoints', tr: 'Engellilik', sayac: 0 },
        { en: 'IsNotSmokerPoints', tr: 'Sigara kullanlımı', sayac: 0 },
        { en: 'CanTravelPoints', tr: 'Seyahat engeli', sayac: 0 },
        { en: 'HasWorkShiftsPoints', tr: 'Vardiyalı çalışamama', sayac: 0 },
        { en: 'HasReferencesPoints', tr: 'Referans eksiği', sayac: 0 },
        { en: 'HasNotCriminalRecordPoints', tr: 'Sabıka Kaydı', sayac: 0 },
        { en: 'CanDrivePoints', tr: 'Aktif araç kullanımı', sayac: 0 },
        { en: 'DriverLicenseTypePoints', tr: 'Ehliyet yetersizliği', sayac: 0 },
        { en: 'EducationLevelPoints', tr: 'Tahsil seviyesi', sayac: 0 },
        { en: 'VocationCollegePoints', tr: 'Meslek yüksek okulu bölümü', sayac: 0 },
        { en: 'VocationHighSchoolPoints', tr: 'Meslek lisesi bölümü', sayac: 0 },
        { en: 'UniversityPoints', tr: 'Üniversite bölümü', sayac: 0 },
        { en: 'PostgraduatePoints', tr: 'Yüksek lisans bölümü', sayac: 0 },
        { en: 'ComputerKnowledge1Points', tr: 'Bilgisayar bilgisi 1', sayac: 0 },
        { en: 'ComputerKnowledge2Points', tr: 'Bilgisayar bilgisi 2', sayac: 0 },
        { en: 'ComputerKnowledge3Points', tr: 'Bilgisayar bilgisi 3', sayac: 0 },
        { en: 'ComputerKnowledge4Points', tr: 'Bilgisayar bilgisi 4', sayac: 0 },
        { en: 'ComputerKnowledge5Points', tr: 'Bilgisayar bilgisi 5', sayac: 0 },
        { en: 'LanguageKnowLedge1Points', tr: 'Yabancı dil bilgisi 1', sayac: 0 },
        { en: 'LanguageKnowledge2Points', tr: 'Yabancı dil bilgisi 2', sayac: 0 },
        { en: 'LanguageKnowledge3Points', tr: 'Yabancı dil bilgisi 3', sayac: 0 },
        { en: 'LanguageKnowledge4Points', tr: 'Yabancı dil bilgisi 4', sayac: 0 },
        { en: 'LanguageKnowledge5Points', tr: 'Yabancı dil bilgisi 5', sayac: 0 },
        { en: 'Certificate1Points', tr: 'Sertifika 1 eksik', sayac: 0 },
        { en: 'Certificate2Points', tr: 'Sertifika 2 eksik', sayac: 0 },
        { en: 'Certificate3Points', tr: 'Sertifika 3 eksik', sayac: 0 },
        { en: 'Certificate4Points', tr: 'Sertifika 4 eksik', sayac: 0 },
        { en: 'Certificate5Points', tr: 'Sertifika 5 eksik', sayac: 0 },
        { en: 'TotalExperienceYearPoints', tr: 'Toplam tecrübe yılı', sayac: 0 },
        { en: 'SectorExperience1Points', tr: 'Sektör 1 uyumsuz', sayac: 0 },
        { en: 'SectorExperience2Points', tr: 'Sektör 2 uyumsuz', sayac: 0 },
        { en: 'SectorExperience3Points', tr: 'Sektör 3 uyumsuz', sayac: 0 },
        { en: 'SectorExperience4Points', tr: 'Sektör 4 uyumsuz', sayac: 0 },
        { en: 'SectorExperience5Points', tr: 'Sektör 5 uyumsuz', sayac: 0 },
        { en: 'PositionExperience1Points', tr: 'Pozisyon 1 tecrübesi eksik', sayac: 0 },
        { en: 'PositionExperience2Points', tr: 'Pozisyon 2 tecrübesi eksik', sayac: 0 },
        { en: 'PositionExperience3Points', tr: 'Pozisyon 3 tecrübesi eksik', sayac: 0 },
        { en: 'PositionExperience4Points', tr: 'Pozisyon 4 tecrübesi eksik', sayac: 0 },
        { en: 'PositionExperience5Points', tr: 'Pozisyon 5 tecrübesi eksik', sayac: 0 },
        { en: 'NumericalAbilityPoints', tr: 'Sayısal yetenek', sayac: 0 },
        { en: 'AnalyticalAbilityPoints', tr: 'Analitik düşünme', sayac: 0 },
        { en: 'AttentionAbilityPoints', tr: 'Dikkat yeteneği', sayac: 0 },
        { en: 'LearnAbilityPoints', tr: 'Öğrenebilme yeteneği', sayac: 0 },
        { en: 'ApplyingAbilityPoints', tr: 'Öğrendiğini uygulayabilme', sayac: 0 },
        { en: 'InnovationAbilityPoints', tr: 'Yenilik ve değişime açıklık', sayac: 0 },
        { en: 'CommunicationAbilityPoints', tr: 'İletişim ve kendini geliştirme', sayac: 0 },
        { en: 'SolutionAbilityPoints', tr: 'Problem çözebilme', sayac: 0 },
        { en: 'PlanningAbilityPoints', tr: 'Planlama', sayac: 0 },
        { en: 'CoordinationAbilityPoints', tr: 'Koordinasyon', sayac: 0 },
        { en: 'ObeyingAbilityPoints', tr: 'Emir komuta', sayac: 0 },
        { en: 'ControllingAbilityPoints', tr: 'Kontrol denetim', sayac: 0 },
        { en: 'DesignAbilityPoints', tr: 'Tasarım Kurgu', sayac: 0 },
        { en: 'RepresentationAbilityPoints', tr: 'Temsil', sayac: 0 },
        { en: 'FinancialRiskPoints', tr: 'Mali risk', sayac: 0 },
        { en: 'LegalRiskPoints', tr: 'Yasal risk', sayac: 0 },
        { en: 'MedicalRiskPoints', tr: 'Sağlık riski', sayac: 0 },
        { en: 'LifeRiskPoints', tr: 'Hayati risk', sayac: 0 },
      ],
      sy: 0,
    }
  },
  mounted() {
    this.getFirmRequest()
  },
  methods: {
    getFirmRequest() {
      let cert = {}
      let comp = {}
      let lang = {}
      let exp = {}
      let qua = {}
      let sec = {}
      apiTalep.GetStaffRequestById(this.firmStaffRequestId).then(req => {
        this.firmRequest = req.data.entity
        apiEval.EvaluationStaffRequestSummary(this.firmStaffRequestId).then(ret => {
          const reqSum = ret.data.entity
          let toplamkyu = 0
          let toplammyu = 0
          let toplamtemyu = 0
          let toplamtekyu = 0
          let toplamrsku = 0
          this.tblbaslik.eslesen = reqSum.evaluationOpenPositionViewModels.filter(x => x.applicant.status === 1).length
          reqSum.evaluationOpenPositionViewModels.forEach(aday => {
            let rej = 0
            rej += aday.personalRejectReasons.length > 0 ? 1 : 0
            rej += aday.vocationalRejectReasons.length > 0 ? 1 : 0
            if (aday.applicant.status === 0) {
              rej = 0
            }
            this.neden(aday.personalRejectReasons)
            this.neden(aday.vocationalRejectReasons)
            toplamkyu += aday.totalPersonalScore
            toplammyu += aday.totalVocationalCompilanceScore
            toplamtemyu += aday.totalBasicAbilityScore
            toplamtekyu += aday.totalTechnicalCompilanceScore
            toplamrsku += aday.riskScore
            this.tblbaslik.elenen += rej > 0 ? 1 : 0
          })
          this.tblbaslik.kyu = toplamkyu !== 0 || this.tblbaslik.eslesen !== 0 ? toplamkyu / this.tblbaslik.eslesen : 0
          this.tblbaslik.myu = toplammyu !== 0 || this.tblbaslik.eslesen !== 0 ? toplammyu / this.tblbaslik.eslesen : 0
          this.tblbaslik.temyu = toplamtemyu !== 0 || this.tblbaslik.eslesen !== 0 ? toplamtemyu / this.tblbaslik.eslesen : 0
          this.tblbaslik.tekyu = toplamtekyu !== 0 || this.tblbaslik.eslesen !== 0 ? toplamtekyu / this.tblbaslik.eslesen : 0
          this.tblbaslik.rsku = toplamrsku !== 0 || this.tblbaslik.eslesen !== 0 ? toplamrsku / this.tblbaslik.eslesen : 0

          this.tblbaslik.reqkyu = reqSum.evaluationStaffRequestSummaryViewModel.requestPersonalScore
          this.tblbaslik.reqmyu = reqSum.evaluationStaffRequestSummaryViewModel.requestVocationalCompilanceScore
          this.tblbaslik.reqtem = reqSum.evaluationStaffRequestSummaryViewModel.requestBasicAbilityScore
          this.tblbaslik.reqtek = reqSum.evaluationStaffRequestSummaryViewModel.requestTechnicalCompilanceScore
          this.tblbaslik.reqrsk = reqSum.evaluationStaffRequestSummaryViewModel.requestRiskScore
          // reqtem: 0, reqtek: 0, reqrsk: 0,
        })
        apiCert.GetStaffRequestCeritificationByRequestId(this.firmStaffRequestId).then(crt => {
          cert = crt.data.entity
          apiComp.GetStaffRequestComputerByRequestId(this.firmStaffRequestId).then(cmp => {
            comp = cmp.data.entity
            apiLang.GetStaffRequestForeignLanguagesByRequestId(this.firmStaffRequestId).then(lng => {
              lang = lng.data.entity
              apiExp.GetStaffRequestPositionExperiencesByRequestId(this.firmStaffRequestId).then(ex => {
                exp = ex.data.entity
                apiQua.GetStaffQualificationByRequestId(this.firmStaffRequestId).then(qu => {
                  qua = qu.data.entity
                  apiSec.GetStaffRequestSectorExperienceByRequestId(this.firmStaffRequestId).then(sc => {
                    sec = sc.data.entity
                    this.tblbaslik.gereksay += sec.sectorImportanceLevel1Id === 3 ? 1 : 0
                    this.tblbaslik.gereksay += sec.sectorImportanceLevel2Id === 3 ? 1 : 0
                    this.tblbaslik.gereksay += sec.sectorImportanceLevel3Id === 3 ? 1 : 0
                    this.tblbaslik.gereksay += sec.sectorImportanceLevel4Id === 3 ? 1 : 0
                    this.tblbaslik.gereksay += sec.sectorImportanceLevel5Id === 3 ? 1 : 0

                    this.tblbaslik.onemlisay += sec.sectorImportanceLevel1Id === 2 ? 1 : 0
                    this.tblbaslik.onemlisay += sec.sectorImportanceLevel2Id === 2 ? 1 : 0
                    this.tblbaslik.onemlisay += sec.sectorImportanceLevel3Id === 2 ? 1 : 0
                    this.tblbaslik.onemlisay += sec.sectorImportanceLevel4Id === 2 ? 1 : 0
                    this.tblbaslik.onemlisay += sec.sectorImportanceLevel5Id === 2 ? 1 : 0

                    this.tblbaslik.farketmezsay += sec.sectorImportanceLevel1Id === 1 ? 1 : 0
                    this.tblbaslik.farketmezsay += sec.sectorImportanceLevel2Id === 1 ? 1 : 0
                    this.tblbaslik.farketmezsay += sec.sectorImportanceLevel3Id === 1 ? 1 : 0
                    this.tblbaslik.farketmezsay += sec.sectorImportanceLevel4Id === 1 ? 1 : 0
                    this.tblbaslik.farketmezsay += sec.sectorImportanceLevel5Id === 1 ? 1 : 0
                  })
                  this.tblbaslik.gereksay += qua.educationImportanceLevelId === 3 ? 1 : 0
                  this.tblbaslik.gereksay += qua.vocationalHighSchoolImportanceLevelId === 3 ? 1 : 0
                  this.tblbaslik.gereksay += qua.vocationalCollegeImportanceLevelId === 3 ? 1 : 0
                  this.tblbaslik.gereksay += qua.universityImportanceLevelId === 3 ? 1 : 0
                  this.tblbaslik.gereksay += qua.postgraduateImportanceLevelId === 3 ? 1 : 0
                  this.tblbaslik.gereksay += qua.yearsOfExperienceImportanceLevelId === 3 ? 1 : 0

                  this.tblbaslik.onemlisay += qua.educationImportanceLevelId === 2 ? 1 : 0
                  this.tblbaslik.onemlisay += qua.vocationalHighSchoolImportanceLevelId === 2 ? 1 : 0
                  this.tblbaslik.onemlisay += qua.vocationalCollegeImportanceLevelId === 2 ? 1 : 0
                  this.tblbaslik.onemlisay += qua.universityImportanceLevelId === 2 ? 1 : 0
                  this.tblbaslik.onemlisay += qua.postgraduateImportanceLevelId === 2 ? 1 : 0
                  this.tblbaslik.onemlisay += qua.yearsOfExperienceImportanceLevelId === 2 ? 1 : 0

                  this.tblbaslik.farketmezsay += qua.educationImportanceLevelId === 1 ? 1 : 0
                  this.tblbaslik.farketmezsay += qua.vocationalHighSchoolImportanceLevelId === 1 ? 1 : 0
                  this.tblbaslik.farketmezsay += qua.vocationalCollegeImportanceLevelId === 1 ? 1 : 0
                  this.tblbaslik.farketmezsay += qua.universityImportanceLevelId === 1 ? 1 : 0
                  this.tblbaslik.farketmezsay += qua.postgraduateImportanceLevelId === 1 ? 1 : 0
                  this.tblbaslik.farketmezsay += qua.yearsOfExperienceImportanceLevelId === 1 ? 1 : 0
                })
                this.tblbaslik.gereksay += exp.positionImportanceLevel1Id === 3 ? 1 : 0
                this.tblbaslik.gereksay += exp.positionImportanceLevel2Id === 3 ? 1 : 0
                this.tblbaslik.gereksay += exp.positionImportanceLevel3Id === 3 ? 1 : 0
                this.tblbaslik.gereksay += exp.positionImportanceLevel4Id === 3 ? 1 : 0
                this.tblbaslik.gereksay += exp.positionImportanceLevel5Id === 3 ? 1 : 0

                this.tblbaslik.onemlisay += exp.positionImportanceLevel1Id === 2 ? 1 : 0
                this.tblbaslik.onemlisay += exp.positionImportanceLevel2Id === 2 ? 1 : 0
                this.tblbaslik.onemlisay += exp.positionImportanceLevel3Id === 2 ? 1 : 0
                this.tblbaslik.onemlisay += exp.positionImportanceLevel4Id === 2 ? 1 : 0
                this.tblbaslik.onemlisay += exp.positionImportanceLevel5Id === 2 ? 1 : 0

                this.tblbaslik.farketmezsay += exp.positionImportanceLevel1Id === 1 ? 1 : 0
                this.tblbaslik.farketmezsay += exp.positionImportanceLevel2Id === 1 ? 1 : 0
                this.tblbaslik.farketmezsay += exp.positionImportanceLevel3Id === 1 ? 1 : 0
                this.tblbaslik.farketmezsay += exp.positionImportanceLevel4Id === 1 ? 1 : 0
                this.tblbaslik.farketmezsay += exp.positionImportanceLevel5Id === 1 ? 1 : 0
              })
              this.tblbaslik.gereksay += lang.languageImportanceLevel1Id === 3 ? 1 : 0
              this.tblbaslik.gereksay += lang.languageImportanceLevel2Id === 3 ? 1 : 0
              this.tblbaslik.gereksay += lang.languageImportanceLevel3Id === 3 ? 1 : 0
              this.tblbaslik.gereksay += lang.languageImportanceLevel4Id === 3 ? 1 : 0
              this.tblbaslik.gereksay += lang.languageImportanceLevel5Id === 3 ? 1 : 0

              this.tblbaslik.onemlisay += lang.languageImportanceLevel1Id === 2 ? 1 : 0
              this.tblbaslik.onemlisay += lang.languageImportanceLevel2Id === 2 ? 1 : 0
              this.tblbaslik.onemlisay += lang.languageImportanceLevel3Id === 2 ? 1 : 0
              this.tblbaslik.onemlisay += lang.languageImportanceLevel4Id === 2 ? 1 : 0
              this.tblbaslik.onemlisay += lang.languageImportanceLevel5Id === 2 ? 1 : 0

              this.tblbaslik.farketmezsay += lang.languageImportanceLevel1Id === 1 ? 1 : 0
              this.tblbaslik.farketmezsay += lang.languageImportanceLevel2Id === 1 ? 1 : 0
              this.tblbaslik.farketmezsay += lang.languageImportanceLevel3Id === 1 ? 1 : 0
              this.tblbaslik.farketmezsay += lang.languageImportanceLevel4Id === 1 ? 1 : 0
              this.tblbaslik.farketmezsay += lang.languageImportanceLevel5Id === 1 ? 1 : 0
            })
            this.tblbaslik.gereksay += comp.computerImportanceLevel1Id === 3 ? 1 : 0
            this.tblbaslik.gereksay += comp.computerImportanceLevel2Id === 3 ? 1 : 0
            this.tblbaslik.gereksay += comp.computerImportanceLevel3Id === 3 ? 1 : 0
            this.tblbaslik.gereksay += comp.computerImportanceLevel4Id === 3 ? 1 : 0
            this.tblbaslik.gereksay += comp.computerImportanceLevel5Id === 3 ? 1 : 0

            this.tblbaslik.onemlisay += comp.computerImportanceLevel1Id === 2 ? 1 : 0
            this.tblbaslik.onemlisay += comp.computerImportanceLevel2Id === 2 ? 1 : 0
            this.tblbaslik.onemlisay += comp.computerImportanceLevel3Id === 2 ? 1 : 0
            this.tblbaslik.onemlisay += comp.computerImportanceLevel4Id === 2 ? 1 : 0
            this.tblbaslik.onemlisay += comp.computerImportanceLevel5Id === 2 ? 1 : 0

            this.tblbaslik.farketmezsay += comp.computerImportanceLevel1Id === 1 ? 1 : 0
            this.tblbaslik.farketmezsay += comp.computerImportanceLevel2Id === 1 ? 1 : 0
            this.tblbaslik.farketmezsay += comp.computerImportanceLevel3Id === 1 ? 1 : 0
            this.tblbaslik.farketmezsay += comp.computerImportanceLevel4Id === 1 ? 1 : 0
            this.tblbaslik.farketmezsay += comp.computerImportanceLevel5Id === 1 ? 1 : 0
          })
          this.tblbaslik.gereksay += cert.certificateImportanceLevel1Id === 3 ? 1 : 0
          this.tblbaslik.gereksay += cert.certificateImportanceLevel2Id === 3 ? 1 : 0
          this.tblbaslik.gereksay += cert.certificateImportanceLevel3Id === 3 ? 1 : 0
          this.tblbaslik.gereksay += cert.certificateImportanceLevel4Id === 3 ? 1 : 0
          this.tblbaslik.gereksay += cert.certificateImportanceLevel5Id === 3 ? 1 : 0

          this.tblbaslik.onemlisay += cert.certificateImportanceLevel1Id === 2 ? 1 : 0
          this.tblbaslik.onemlisay += cert.certificateImportanceLevel2Id === 2 ? 1 : 0
          this.tblbaslik.onemlisay += cert.certificateImportanceLevel3Id === 2 ? 1 : 0
          this.tblbaslik.onemlisay += cert.certificateImportanceLevel4Id === 2 ? 1 : 0
          this.tblbaslik.onemlisay += cert.certificateImportanceLevel5Id === 2 ? 1 : 0

          this.tblbaslik.farketmezsay += cert.certificateImportanceLevel1Id === 1 ? 1 : 0
          this.tblbaslik.farketmezsay += cert.certificateImportanceLevel2Id === 1 ? 1 : 0
          this.tblbaslik.farketmezsay += cert.certificateImportanceLevel3Id === 1 ? 1 : 0
          this.tblbaslik.farketmezsay += cert.certificateImportanceLevel4Id === 1 ? 1 : 0
          this.tblbaslik.farketmezsay += cert.certificateImportanceLevel5Id === 1 ? 1 : 0
        })
        this.tblbaslik.gereksay += this.firmRequest.genderImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.maritalImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.ageImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.weightImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.heightImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.militaryStatusImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.townsImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.isNotDisabledImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.isNotSmokerImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.canTravelImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.hasWorkShiftsImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.hasReferencesImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.hasNotCriminalRecordImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.canDriveImportanceLevelId === 3 ? 1 : 0
        this.tblbaslik.gereksay += this.firmRequest.driverLicenseImportanceLevelId === 3 ? 1 : 0

        this.tblbaslik.onemlisay += this.firmRequest.genderImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.maritalImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.ageImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.weightImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.heightImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.militaryStatusImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.townsImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.isNotDisabledImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.isNotSmokerImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.canTravelImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.hasWorkShiftsImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.hasReferencesImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.hasNotCriminalRecordImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.canDriveImportanceLevelId === 2 ? 1 : 0
        this.tblbaslik.onemlisay += this.firmRequest.driverLicenseImportanceLevelId === 2 ? 1 : 0

        this.tblbaslik.farketmezsay += this.firmRequest.genderImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.maritalImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.ageImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.weightImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.heightImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.militaryStatusImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.townsImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.isNotDisabledImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.isNotSmokerImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.canTravelImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.hasWorkShiftsImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.hasReferencesImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.hasNotCriminalRecordImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.canDriveImportanceLevelId === 1 ? 1 : 0
        this.tblbaslik.farketmezsay += this.firmRequest.driverLicenseImportanceLevelId === 1 ? 1 : 0
      })
    },
    neden(sebep) {
      // { en: 'GenderPoints', tr: 'Cinisyet', sayac: 0 },
      sebep.forEach(x => {
        this.elemeNeden.forEach((ob, i) => {
          if (ob.en === x) {
            this.elemeNeden[i].sayac += 1
          }
        })
      })
      // return true
    },
  },
}
</script>
